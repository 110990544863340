import { useEffect, useState } from 'react'
import {
  Checkbox,
  Divider,
  Grid,
  Text,
  RangeSlider,
  Box,
  Card,
  Image,
  Group,
  Button,
  Transition,
  ActionIcon,
  Select
} from '@mantine/core'
import { useSelector, useDispatch } from 'react-redux'
import { MdOutlineClose } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import InsuranceCard from '../components/InsuranceCard'
import InsurancesFilterNoData from '../components/InsurancesFilterNoData'
import { setProductsToCompare } from '../../../store/cotizationSlice'

const pesosLocale = Intl.NumberFormat('es')
// const MAX_INSURANCE_TO_COMPARE = 4
const COMPARE_PLACEHOLDER = ['1', '2', '3', '4']

function Cotization() {
  const { apiResponse, productsToCompare, formAnswers } = useSelector(
    (state) => state.cotization
  )
  const dispatch = useDispatch()
  const { uniqueTupleId } = useParams()
  const [formatedCards, setFormatedCards] = useState([])
  const [allData, setAllData] = useState([])
  const [orderValue, setOrderValue] = useState('LOWER_PRICE')
  const [filterPlans, setFilterPlans] = useState({
    productoList: [],
    priceRangeClp: [],
    priceRangeUf: [],
    deducibleList: []
  })
  const [filtersValues, setFiltersValues] = useState({
    productoList: [],
    priceRangeClp: [],
    priceRangeUf: [],
    deducibleList: []
  })
  const navigate = useNavigate()

  useEffect(() => {
    const formatedOptions = []
    const productoList = []
    const priceRangeClp = []
    const priceRangeUf = []
    const deducibleList = []

    if (apiResponse) {
      const { fidResponse, suraResponse } = apiResponse

      if (suraResponse) {
        suraResponse?.OfertasBase?.OfertaBase.forEach((producto) => {
          producto.Deducibles.DeducibleBase.forEach((deducibles) => {
            formatedOptions.push({
              ...producto,
              ...deducibles,
              type: 'SURA',
              image: 'https://seguros.sura.cl/assets/img/logo_sura.svg',
              id: formatedOptions.length
            })
          })
        })
      }

      if (fidResponse.items) {
        fidResponse.items.forEach((fidItem) => {
          fidItem.quotations.forEach((quotation) => {
            formatedOptions.push({
              id: formatedOptions.length,
              Opcionales: { Opcional: [{ descripcion: '-', factor: '0' }] },
              codigoDeducible: formatedOptions.length + 1,
              deducible: `${quotation.deductible} UF`,
              nombrePlan: quotation.plan.name,
              valorEnPeso: quotation.monthlyPremium,
              valorEnUf: quotation.netPremium,
              valorUf: quotation.valueUf,
              codigoProducto: 1,
              producto: '-',
              type: 'FID',
              fidItemId: fidItem.itemId,
              quotationId: quotation.id,
              fidDealID: fidResponse.dealId,
              image:
                'https://www.fidseguros.cl/content/uploads/2022/10/logo-header.svg'
            })
          })
        })
      }

      formatedOptions.forEach((item) => {
        priceRangeClp.push(item.valorEnPeso)
        priceRangeUf.push(item.valorEnUf)
        deducibleList.push(item.deducible)
        productoList.push(item.producto)
      })

      formatedOptions.sort((a, b) => a.valorEnPeso - b.valorEnPeso)
      setFormatedCards(formatedOptions)
      setAllData(formatedOptions)
      setFilterPlans({
        productoList: [...new Set(productoList)],
        priceRangeClp,
        priceRangeUf,
        deducibleList: [...new Set(deducibleList)]
      })
      setFiltersValues((prev) => ({
        ...prev,
        priceRangeClp: [
          +Math.min(...priceRangeClp),
          +Math.max(...priceRangeClp)
        ],
        priceRangeUf: [+Math.min(...priceRangeUf), +Math.max(...priceRangeUf)]
      }))
    }
  }, [apiResponse])

  const handlePlansFilterChange = (value) => {
    if (value.length === 0) {
      setFormatedCards(allData)
    } else {
      const filtered = allData.filter((item) => value.includes(item.producto))
      setFormatedCards(filtered)
    }
  }

  const handleDeducibleFilterChange = (value) => {
    if (value.length === 0) {
      setFormatedCards(allData)
    } else {
      const filtered = allData.filter((item) => value.includes(item.deducible))
      setFormatedCards(filtered)
    }
  }

  const handleClpRangeChange = (value) => {
    const [min, max] = value
    const filtered = allData.filter(
      (item) => item.valorEnPeso >= min && item.valorEnPeso <= max
    )

    setFiltersValues({ ...filtersValues, priceRangeClp: value })
    setFormatedCards(filtered)
  }

  const handleUfRangeChange = (value) => {
    const [min, max] = value
    const filtered = allData.filter(
      (item) => item.valorEnUf >= min && item.valorEnUf <= max
    )
    setFiltersValues({ ...filtersValues, priceRangeUf: value })
    setFormatedCards(filtered)
  }

  const handleCompareRemove = (itemData) => {
    const filtered = productsToCompare.filter(
      (item) => item.nombrePlan !== itemData.nombrePlan
    )
    dispatch(setProductsToCompare(filtered))
  }

  const handleOrderChange = (newValue) => {
    const copyOfData = formatedCards.map((item) => ({ ...item }))

    if (newValue === 'LOWER_PRICE') {
      copyOfData.sort((a, b) => a.valorEnPeso - b.valorEnPeso)
    }
    if (newValue === 'HIGHER_PRICE') {
      copyOfData.sort((a, b) => b.valorEnPeso - a.valorEnPeso)
    }
    setFormatedCards(copyOfData)
    setOrderValue(newValue)
  }

  return (
    <Grid>
      <Grid.Col
        xs={12}
        sx={{ backgroundColor: 'orange', padding: '16px 48px' }}
      >
        <Text fw={700} lh={1.5}>
          Datos
        </Text>
        {/* <Text fw={700} lh={1.5}>
          RENAULT, DUSTER DYNAMITE 1.6 2015
        </Text> */}
        <Text lh={1.2}>RUT: {formAnswers?.formAnswers['3']}</Text>
        <Text lh={1.2}>
          Nombre:{' '}
          {`${formAnswers?.formAnswers['1']} ${formAnswers?.formAnswers['14']} ${formAnswers?.formAnswers['15']}`}
        </Text>
      </Grid.Col>
      <Grid.Col xs={12} sx={{ padding: '0px 32px' }}>
        <Grid>
          <Grid.Col xs={2.5}>
            <Grid p={16} gutter={24}>
              <Grid.Col xs={12}>
                <Text mb={8}>Filtrar por:</Text>
              </Grid.Col>
              <Grid.Col xs={12}>
                <Text fw={700} mb={8}>
                  Tipo de seguro
                </Text>
                <Checkbox.Group onChange={handlePlansFilterChange}>
                  {filterPlans.productoList.map((producto) => (
                    <Checkbox
                      key={producto}
                      my={2}
                      value={producto}
                      label={producto}
                    />
                  ))}
                </Checkbox.Group>
              </Grid.Col>
              <Grid.Col xs={12}>
                <Divider />
              </Grid.Col>
              <Grid.Col xs={12}>
                <Text fw={700} mb={8}>
                  Precios CLP
                </Text>
                <RangeSlider
                  radius="md"
                  value={filtersValues.priceRangeClp}
                  onChange={handleClpRangeChange}
                  min={+Math.min(...filterPlans.priceRangeClp)}
                  max={+Math.max(...filterPlans.priceRangeClp)}
                  marks={[
                    {
                      value: +Math.min(...filterPlans.priceRangeClp),
                      label: pesosLocale.format(
                        Math.min(...filterPlans.priceRangeClp)
                      )
                    },
                    {
                      value: +Math.max(...filterPlans.priceRangeClp),
                      label: pesosLocale.format(
                        Math.max(...filterPlans.priceRangeClp)
                      )
                    }
                  ]}
                />
              </Grid.Col>
              <Grid.Col xs={12}>
                <Divider />
              </Grid.Col>
              {filterPlans.priceRangeUf.length > 0 && (
                <>
                  <Grid.Col xs={12}>
                    <Text fw={700} mb={8}>
                      Precios UF
                    </Text>
                    <RangeSlider
                      radius="md"
                      onChange={handleUfRangeChange}
                      value={filtersValues.priceRangeUf}
                      min={+Math.min(...filterPlans.priceRangeUf)}
                      max={+Math.max(...filterPlans.priceRangeUf)}
                      marks={[
                        {
                          value: +Math.min(...filterPlans.priceRangeUf),
                          label: Math.min(...filterPlans.priceRangeUf)
                        },
                        {
                          value: +Math.max(...filterPlans.priceRangeUf),
                          label: Math.max(...filterPlans.priceRangeUf)
                        }
                      ]}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Divider />
                  </Grid.Col>
                </>
              )}
              {filterPlans.deducibleList.length > 0 && (
                <>
                  <Grid.Col xs={12}>
                    <Text fw={700} mb={8}>
                      Deducibles
                    </Text>
                    <Checkbox.Group onChange={handleDeducibleFilterChange}>
                      {filterPlans.deducibleList.map((producto) => (
                        <Checkbox
                          key={producto}
                          my={2}
                          value={producto}
                          label={producto}
                        />
                      ))}
                    </Checkbox.Group>
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Divider />
                  </Grid.Col>
                </>
              )}
            </Grid>
          </Grid.Col>
          <Grid.Col xs={9.5}>
            <Grid p={16} gutter={24} align="center">
              <Grid.Col xs={6}>
                <Text mb={8}>{formatedCards.length} opciones de seguros</Text>
              </Grid.Col>
              <Grid.Col xs={6}>
                <Group position="right">
                  <Select
                    label="Ordenar por:"
                    variant="unstyled"
                    value={orderValue}
                    onChange={handleOrderChange}
                    sx={{ width: '30%' }}
                    data={[
                      {
                        value: 'HIGHER_PRICE',
                        label: 'De mayor a menor precio'
                      },
                      { value: 'LOWER_PRICE', label: 'De menor a mayor precio' }
                    ]}
                  />
                </Group>
              </Grid.Col>
              {formatedCards.length > 0 ? (
                formatedCards.map((insurance) => (
                  <Grid.Col key={insurance.nombrePlan} xs={12} sm={6} md={4}>
                    <InsuranceCard insurance={insurance} />
                  </Grid.Col>
                ))
              ) : (
                <Grid.Col xs={12}>
                  <InsurancesFilterNoData />
                </Grid.Col>
              )}
            </Grid>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Transition transition="slide-up" mounted={productsToCompare.length > 0}>
        {(transitionStyles) => (
          <Box
            style={transitionStyles}
            sx={{
              position: 'fixed',
              bottom: 0,
              width: '100vw',
              backgroundColor: '#F9F8F8',
              padding: 16,
              borderTop: '1px solid lightgrey'
            }}
          >
            <Group position="center">
              {productsToCompare.map((item) => (
                <Card
                  key={item.nombrePlan}
                  shadow="xl"
                  radius="lg"
                  withBorder
                  sx={{ margin: 12, width: 200 }}
                >
                  <ActionIcon
                    onClick={() => handleCompareRemove(item)}
                    sx={{ position: 'absolute', top: 10, right: 10 }}
                  >
                    <MdOutlineClose />
                  </ActionIcon>
                  <Card.Section
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}
                    pt={16}
                  >
                    <Text>Deducible</Text>
                    <Text fw={700}>UF 0</Text>
                    <Text fw={700} lh={1} fz={32} sx={{ color: 'orange' }}>
                      $ {pesosLocale.format(item.valorEnPeso)}
                    </Text>
                    <Text fz={15} lh={2} sx={{ color: '#E24515' }}>
                      UF {item.valorEnUf} mensual
                      <Divider
                        size="lg"
                        sx={{ color: '#767689', fontSize: 12 }}
                      />
                    </Text>
                  </Card.Section>
                  <Card.Section
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <Image
                      src={item.image}
                      height={67}
                      width={70}
                      alt="Norway"
                      fit="contain"
                    />
                  </Card.Section>
                </Card>
              ))}
              {COMPARE_PLACEHOLDER.slice(productsToCompare.length).map(
                (item) => (
                  <Card
                    key={item}
                    shadow="xl"
                    radius="lg"
                    withBorder
                    sx={{
                      margin: 12,
                      width: 200,
                      height: 200,
                      backgroundColor: 'rgba(118, 118, 137, 0.33)'
                    }}
                  />
                )
              )}
              <Button
                color="orange"
                my="xs"
                radius="md"
                onClick={() => navigate(`/cotiza/${uniqueTupleId}/compare`)}
              >
                Comparar
              </Button>
            </Group>
          </Box>
        )}
      </Transition>
    </Grid>
  )
}

export default Cotization
